import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import Text from 'components/Text';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  defs,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ContinueButton from 'pages/start/quiz/components/QuizContinueButton';
import NowSvg from 'assets/icons/results/now.svg';
import AfterSvg from 'assets/icons/results/after.svg';

interface InfoProps {
  item: {
    label: string;
    subtitle: string;
    now: string;
    after: string;
    you: string;
    time: string[];
  };
  onContinue: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 2.25rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.5rem;

  @media ${tablet} {
  }
`;

const chartData = [
  { name: 'Page A', uv: 1500, pv: 0, amt: 6000 },
  { name: 'Page B', uv: 1520, pv: 0, amt: 4300 },
  { name: 'Page C', uv: 1200, pv: 0, amt: 3500 },
  { name: 'Page D', uv: 1500, pv: 0, amt: 2950 },
  { name: 'Page E', uv: 1200, pv: 0, amt: 1700 },
  { name: 'Page F', uv: 1500, pv: 0, amt: 1200 },
  { name: 'Page G', uv: 1200, pv: 0, amt: 1020 },
  { name: 'Page H', uv: 1500, pv: 0, amt: 900 },
  { name: 'Page F', uv: 1500, pv: 0, amt: 700 },
];

const Chart: FC<InfoProps> = ({ item, onContinue, ...props }) => {
  const { isTablet } = useQuery();
  const [showCurrent, setShowCurrent] = useState(false);
  const [showAfter, setShowAfter] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentTimer = setTimeout(() => setShowCurrent(true), 600);
    const afterTimer = setTimeout(() => setShowAfter(true), 1400);

    return () => {
      clearTimeout(currentTimer);
      clearTimeout(afterTimer);
    };
  }, []);

  const handleClick = () => {
    setLoading(true);
    onContinue();
  };

  return (
    <Container {...props}>
      <Title dangerouslySetInnerHTML={{ __html: item?.label }} />
      <Subtitle dangerouslySetInnerHTML={{ __html: item?.subtitle }} />
      <Your>{item?.you}</Your>
      <ChartContainer>
        <NowSvgStyled visible={showCurrent} />
        <NowContainer visible={showCurrent}>
          <NowText>{item?.now}</NowText>
        </NowContainer>
        <AfterSvgStyled visible={showAfter} />
        <AfterContainer visible={showAfter}>
          <AfterText dangerouslySetInnerHTML={{ __html: item?.after }} />
        </AfterContainer>

        <ResponsiveContainer width="100%" height={isTablet ? 174 : 216}>
          <ComposedChart
            width={411}
            height={isTablet ? 174 : 216}
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <YAxis
              tickCount={4} // Set the number of ticks
              axisLine={false} // Remove the axis line
              tickLine={false} // Remove tick marks
              hide
            />

            <CartesianGrid
              stroke="#D1C9B6"
              strokeDasharray="4 0"
              vertical={false}
              horizontal={true}
            />
            <defs>
              <linearGradient
                id="colorUv"
                x1="4.00913"
                y1="54.1714"
                x2="424.009"
                y2="273.457"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.048593" stopColor="#FF7463" />
                <stop offset="0.251415" stopColor="#FFDB6E" />
                <stop offset="0.432098" stopColor="#A9E270" />
                <stop offset="0.670704" stopColor="#A9E270" />
                <stop offset="0.75" stopColor="#5daf38" />
                <stop offset="1" stopColor="#6DC645" />
              </linearGradient>
            </defs>

            <Area
              type="monotone"
              dataKey="amt"
              stroke="url(#colorUv)"
              fill="url(#colorUv)"
              strokeWidth={5}
              fillOpacity={0.4}
              animationDuration={1700}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
      <Times>
        {item?.time.map((item, index) => (
          <Time key={index}>{item}</Time>
        ))}
      </Times>

      <ContinueButton
        title={item?.nextBtnTitle}
        onClick={handleClick}
        loading={loading}
        disabled={loading}
      />
    </Container>
  );
};

export default Chart;

const Your = styled.p`
  color: #777;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2.51rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1.125rem;
    padding: 0 1rem 1.89rem;
  }
`;

const NowText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;

const NowSvgStyled = styled(NowSvg)<{ visible: boolean }>`
  position: absolute;
  top: -1.5rem;
  left: -1rem;

  z-index: 3;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  @media ${tablet} {
    top: -20px;
    left: 2px;
    max-width: 6.5rem;
  }
`;

const NowContainer = styled.section<{ visible: boolean }>`
  position: absolute;
  top: -8%;
  left: 7.5%;

  z-index: 4;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  @media ${tablet} {
    top: -7%;
    left: 50px;
  }
`;

const AfterText = styled.p`
  color: #fff;
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  span {
    display: block;
  }
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;

const AfterSvgStyled = styled(AfterSvg)<{ visible: boolean }>`
  position: absolute;
  bottom: 0.5rem;
  right: -1rem;

  z-index: 3;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  @media ${tablet} {
    bottom: 0;
    right: 0.3rem;

    max-width: 4rem;
  }
`;

const AfterContainer = styled.section<{ visible: boolean }>`
  position: absolute;
  bottom: 3.2rem;
  right: -0.3rem;
  z-index: 4;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  @media ${tablet} {
    bottom: 24%;
    right: 1rem;
  }
`;

const ChartContainer = styled.section`
  position: relative;
  width: 100%;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const Subtitle = styled.p`
  color: #777;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  span {
    font-weight: 700;
  }
  padding-bottom: 3rem;
`;

const Times = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.67056rem;
  margin-bottom: 2.375rem;
  @media ${tablet} {
    padding: 0.5rem 1rem 0;
  }
`;

const Time = styled.span`
  color: #777;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.50875rem;
  @media ${tablet} {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }
`;
