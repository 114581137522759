import React, { memo, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';

import { config } from '../../config';

export const withApp = (component: React.ReactNode) => {
  const WrappedComponent: React.FC = memo(() => {
    useEffect(() => {
      if (config.ENV === 'production') {
        Sentry.init({
          dsn: config.SENTRY_DNS,
          replaysSessionSampleRate: 0.01,
          replaysOnErrorSampleRate: 1.0,
          integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
          tracesSampleRate: 0.01,
          tracePropagationTargets: [/^https:\/\/walking\.yoga/],
        });
        Sentry.setTag('rnd-project', config.NAME);
        Sentry.setTag('rnd-env', config.ENV);

        datadogRum.init({
          applicationId: '42935d44-f0fc-409b-87ff-0f1497e2791c',
          clientToken: config.DATADOG_TOKEN || '',
          site: 'datadoghq.com',
          service: 'walking-yoga',
          env: 'prod',
          sessionSampleRate: 10,
          sessionReplaySampleRate: 10,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
      }
    }, []);

    return <>{component}</>;
  });
  WrappedComponent.displayName = 'WrappedComponent';

  return <WrappedComponent />;
};
