import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Headline, LoadingPage, PrimaryButton, Reviews, Seo } from 'components';
import Header from 'components/Header';
import { mobile, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import SeparatorIcon from 'assets/icons/separator.svg';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';

import StartNowButton from './components/StartNowButton';

import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import Chart from './components/Chart';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  padding: 0.625rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  @media ${mobile} {
    min-width: 7.06rem;
  }
`;

const Separator = styled(SeparatorIcon)`
  width: 100vw;
`;

const EvenList = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media ${mobile} {
    flex-direction: column;

    div:nth-child(1n):not(last-child) {
      margin-bottom: 1.66rem;
    }
  }
`;

const StartNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();
  const user = useSelector((state: AppState) => state.user);

  const quizData = useQuizData('results');

  const { goToEmail, goToCheckout, goToOnBoarding } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : 'f',
    email: user.user?.email,
  });

  const handleContinue = () => {
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return window.location.search.includes('tst3')
        ? goToOnBoarding()
        : goToCheckout({ discount: false });
    }
    window.location.search.includes('tst3') ? goToOnBoarding() : goToEmail();
  };

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;

    switch (item.type) {
      default: {
        return <React.Fragment key={key} />;
      }
      case 'chart': {
        return <Chart item={item} onContinue={handleContinue} key={key} />;
      }
    }
  };

  if (!quizData) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" RightComponent={null} />
      {quizData?.sections?.map(renderSection)}
    </>
  );
};

export default Results;
