import React, { FC, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { LoadingPage, PageContainer, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { tablet, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import LoadingBar from './components/LoadingBar';
import Checklist from './components/Checklist';
import { normalizeStates } from 'utils/localization';
import { CircularProgress } from '@material-ui/core';

const BoxList = React.lazy(() => import('../checkout/components/BoxList'));

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 3.5rem 1rem 3.5rem;
  }
`;

const ImageContainer = styled.div`
  max-width: 16rem;
`;
const Image = styled.img`
  width: 100%;
`;

const Title = styled(Text)`
  color: #3fa134;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1.75rem;
`;

const Analyzing = styled(Text).attrs({
  type: 'body',
})`
  margin: 1.5rem 0;
  transition: opacity ease-in-out 2s;
  opacity: 1;
`;

const ImageWrapper = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
  svg {
    width: 11.25rem;
    height: 11.25rem;
  }
`;

const StyledPercent = styled(Text)`
  position: absolute;
  color: #3fa134;
  text-align: center;
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 4.05rem */
  letter-spacing: -0.02969rem;
`;

const BoxContainer = styled.div`
  @media ${tablet} {
    padding: 0 0 5rem;
  }
`;

const LOADING_DURATION = 7500;
const INTERVAL = 75;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const data = useQuizData('calculating');
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );

  const { goToResults, goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : 'f',
    email: user?.email,
  });

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [window.location.href]);

  useEffect(() => {
    let tickCount = 0;
    const totalTicks = LOADING_DURATION / INTERVAL;

    // Ensure progress starts at 0
    setProgress(0);

    const animationInterval = setInterval(() => {
      tickCount += 1;
      const newProgress = Math.min((tickCount / totalTicks) * 100, 100);
      setProgress(newProgress);

      if (tickCount >= totalTicks) {
        clearInterval(animationInterval);
        setIsLoading(false);
      }
    }, INTERVAL);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const completeTimer = setTimeout(() => {
        goToEmail();
      }, 1000);

      return () => clearTimeout(completeTimer);
    }
  }, [isLoading]);

  const handleNavigationOnCounterEnd = () => {
    goToEmail();
  };

  if (!data) {
    return <LoadingPage />;
  }

  const {
    title,
    progressTitle,
    imageUrl,
    list,
    modalQuestion,
    modalAnswer1,
    modalAnswer2,
    showModal,
    boxList,
  } = data;

  return (
    <>
      <Seo />

      <Header color="light0" hasDivider={true} />
      {/* {showResults ? (
        <BoxContainer>
          <Suspense
            fallback={
              <ProgressContainer>
                <CircularProgress />
              </ProgressContainer>
            }
          >
            <BoxList {...boxList} onClick={() => goToEmail()} />
          </Suspense>
        </BoxContainer>
      ) : ( */}
      <ContentContainer>
        {/* <ImageContainer>
            <Image src={imageUrl} />
          </ImageContainer> */}
        <div>
          <ImageWrapper>
            <CircularProgressbar
              value={progress}
              text=""
              styles={buildStyles({
                pathTransitionDuration: 0.25,
                pathColor: '#3FA134',
                trailColor: '#F6F4EE',
                strokeLinecap: 'round',
              })}
            />
            <StyledPercent>{Math.round(progress)}%</StyledPercent>
          </ImageWrapper>
        </div>
        <Title type={'h1'}>{title}</Title>
        {/* <LoadingBar progress={progress} />
          <Analyzing>{progressTitle}</Analyzing> */}
        <Checklist
          list={list}
          onEnd={handleNavigationOnCounterEnd}
          onProgress={progress => setProgress(progress)}
          modalQuestion={modalQuestion}
          modalAnswer1={modalAnswer1}
          modalAnswer2={modalAnswer2}
          showModal={showModal}
        />
      </ContentContainer>
      {/* )} */}
    </>
  );
};

export default Calculating;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 10.66rem;
  align-items: center;
`;
