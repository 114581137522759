import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { link } from 'fs';
import DownloadImg from 'assets/icons/success/download-icon.svg';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Heading = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4rem;

  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

const InnerItem = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

const LifetimeSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 4.8rem;
  @media ${tablet} {
    padding: 1rem 0 0 2.7rem;
  }
`;

const LifetimeTitle = styled.p`
  color: #555770;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01375rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid rgba(119, 119, 119, 0.1);
  padding: 1.5rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  upsellsKeys,
  bundlePhoto,
}) => {
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );
  const isOver50 = quiz_answers?.age?.year >= 50;
  const isUnder50 = quiz_answers?.age?.year < 50;

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        if (
          str.includes('walking-yoga_upsell_menstrual-based-diet_otp_pdf') &&
          isOver50
        ) {
          return;
        }
        if (
          str.includes('walking-yoga_upsell_menopause-diet-plan_otp_pdf') &&
          isUnder50
        ) {
          return;
        }
        result += bundlePhoto[str].toString();
      }
    });

    return result;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, // Scroll to the top
      behavior: 'smooth', // Smooth scrolling
    });
  };

  const resolvePageTextContent = product => {
    if (product?.key.includes('walking-yoga_upsell_book-bundle_otp_pdf')) {
      const planKeys = upsell_products.map(up => up.key);
      return {
        isBundle: true,
        links: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes(
              'walking-yoga_upsell_cortisol-detox-challenge_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/WY_CortisolDetox.pdf?alt=media&token=1b00505e-47f5-4f80-b4c7-6e4c67cccd3a"
                target="_blank"
              >
                <DownloadImg />
                30-Day Cortisol Detox Challenge
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_sleep-guide_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/The%20Ultimate%20Sleep%20Guide.pdf?alt=media&token=354c8cbb-25f6-4801-a247-e7eb830d69e2"
                target="_blank"
              >
                <DownloadImg />
                Ultimate sleep guide
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_fasting-made-easy_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Fasting%20Made%20Easy.pdf?alt=media&token=ee91afd5-0249-4c67-9948-549919aef27e"
                target="_blank"
              >
                <DownloadImg />
                Fasting Made Easy
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_habits-to-get-fitter_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Healthy%20Habits%20for%20Getting%20Fitter.pdf?alt=media&token=bf5743b1-57d0-453c-aaa1-98e758030109"
                target="_blank"
              >
                <DownloadImg />
                Healthy Habits to Get Fitter
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_menstrual-based-diet_otp_pdf',
            ) || isOver50 ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Menstrual%20Cycle-Based%20Diet%20Plan%20for%20Women.pdf?alt=media&token=2b34a148-c2a0-4748-bca2-060232a1ed1a"
                target="_blank"
              >
                <DownloadImg />
                Menstrual Cycle-Based Diet Plan for Women
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_menopause-diet-plan_otp_pdf',
            ) || isUnder50 ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/WY_Menopause.pdf?alt=media&token=cb649bc6-3666-4955-95ba-6fd132cf9077"
                target="_blank"
              >
                <DownloadImg />
                The Ultimate Menopause Diet Plan
              </Download>
            )}
            {planKeys.includes(
              'walking-yoga_upsell_mental-health-workbook_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Mental%20Health%20Workbook.pdf?alt=media&token=654b7bab-aadf-4e75-b8e0-ad472add77fb"
                target="_blank"
              >
                <DownloadImg />
                Mental Health Workbook
              </Download>
            )}
          </MultipleUpsellLinkContainer>
        ),
      };
    }
    if (
      product?.key.includes(
        'walking-yoga_upsell_cortisol-detox-challenge_otp_pdf',
      )
    ) {
      return {
        img: 'upgrade/detox/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/WY_CortisolDetox.pdf?alt=media&token=1b00505e-47f5-4f80-b4c7-6e4c67cccd3a',
        ],
      };
    }
    if (product?.key.includes('walking-yoga_upsell_sleep-guide_otp_pdf')) {
      return {
        img: 'upgrade/sleep-guide/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/The%20Ultimate%20Sleep%20Guide.pdf?alt=media&token=354c8cbb-25f6-4801-a247-e7eb830d69e2',
        ],
      };
    }
    if (
      product?.key.includes('walking-yoga_upsell_fasting-made-easy_otp_pdf')
    ) {
      return {
        img: 'upgrade/fasting/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Fasting%20Made%20Easy.pdf?alt=media&token=ee91afd5-0249-4c67-9948-549919aef27e',
        ],
      };
    }
    if (
      product?.key.includes('walking-yoga_upsell_habits-to-get-fitter_otp_pdf')
    ) {
      return {
        img: 'upgrade/get-fitter/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Healthy%20Habits%20for%20Getting%20Fitter.pdf?alt=media&token=bf5743b1-57d0-453c-aaa1-98e758030109',
        ],
      };
    }
    if (
      product?.key.includes('walking-yoga_upsell_menstrual-based-diet_otp_pdf')
    ) {
      return {
        img: 'upgrade/menstrual/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Menstrual%20Cycle-Based%20Diet%20Plan%20for%20Women.pdf?alt=media&token=2b34a148-c2a0-4748-bca2-060232a1ed1a',
        ],
      };
    }
    if (
      product?.key.includes('walking-yoga_upsell_menopause-diet-plan_otp_pdf')
    ) {
      return {
        img: 'upgrade/menopause/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/WY_Menopause.pdf?alt=media&token=cb649bc6-3666-4955-95ba-6fd132cf9077',
        ],
      };
    }
    if (
      product?.key.includes(
        'walking-yoga_upsell_mental-health-workbook_otp_pdf',
      )
    ) {
      return {
        img: 'upgrade/mental-health/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/walking-yoga-app-prod.appspot.com/o/Mental%20Health%20Workbook.pdf?alt=media&token=654b7bab-aadf-4e75-b8e0-ad472add77fb',
        ],
      };
    }
  };

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);
    const data = resolvePageTextContent(upsellProduct);
    if (upsell_product.key.includes('walking-yoga_upsell_lifetime_sub')) {
      return;
    }
    return (
      <OrderItemContainer key={index}>
        <InnerItem>
          <StyledImg
            src={
              data?.isBundle
                ? `upgrade/bundle/bundle-${getBundlePhoto()}.png`
                : data?.img
            }
            alt=""
          />
          <UpsellInner>
            <ProductTitle>{upsellProduct.name}</ProductTitle>
            {!data?.isBundle ? (
              <Download href={data?.link} target="_blank">
                <DownloadImg />
                Download here
              </Download>
            ) : (
              data.links
            )}
          </UpsellInner>
          <Price>
            {upsellProduct.currency}
            {upsellProduct.finalPrice}
          </Price>
        </InnerItem>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce(
    (sum, p) => sum + parseFloat(p?.finalPrice || '0'),
    0,
  );

  const productVisiblePrice = selectedProduct?.hasTrial
    ? selectedProduct?.discountedTrialPrice
    : selectedProduct?.finalPrice;

  const totalPrice = (
    parseFloat(Number(productVisiblePrice)) + parseFloat(upsellsPrice)
  ).toFixed(2);

  const lifetimeProduct = upsell_products.find(item =>
    item.key.includes('walking-yoga_upsell_lifetime_sub'),
  );

  const localisedLifetime = getLocalisedProduct(lifetimeProduct);

  return (
    <Container>
      <Heading>{title}</Heading>
      <SummaryContainer>
        <OrderItemContainer>
          <InnerItem>
            <StyledImg src="success/success-app.png" alt="" />
            <UpsellInner>
              <ProductTitle>
                {selectedProduct.name.replace('Walking yoga ', '')}
              </ProductTitle>
              <DownloadApp onClick={scrollToTop}>
                <DownloadImg />
                Download here
              </DownloadApp>
            </UpsellInner>
            <Price>
              {selectedProduct.currency}
              {Number(productVisiblePrice)}
            </Price>
          </InnerItem>
          {localisedLifetime.key && (
            <LifetimeSection>
              <LifetimeTitle>{localisedLifetime.name}</LifetimeTitle>
              <Price>
                {localisedLifetime.currency}
                {localisedLifetime.finalPrice}
              </Price>
            </LifetimeSection>
          )}
        </OrderItemContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Total>Total</Total>
          <Total>
            {selectedProduct.currency}
            {totalPrice}
          </Total>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;

const ProductTitle = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.375rem;
  }
`;

const Price = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }
`;

const Download = styled.a`
  cursor: pointer;
  color: #3fa134 !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const DownloadApp = styled.p`
  cursor: pointer;
  color: #3fa134 !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &:hover {
    opacity: 0.7;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Total = styled.p`
  color: #000;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
`;

const UpsellInner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

const StyledImg = styled(DynamicImage)`
  width: 4.14144rem;
  margin-right: 1rem;
  @media ${tablet} {
    width: 2.48488rem;
    margin-right: 0;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
